body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  display: inline-block;
}
*,
::after,
::before {
  padding: 0;
  margin: 0;
}
html {
  height: max-content;
}
body,
#root,
.App {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Bowler';
  src: url('images/fonts/ofont.ru_Bowler.ttf') format('truetype');
  font-weight: 500;
}
:root {
  --ff-bowler: 'Bowler', sans-serif;
  --ff-exo-2: 'Exo 2', serif;
  --color-black: #141416;
  --color-white: #fff;
  --color-ylw: #ffd951;
  --color-gray: #a9a9a9;
  --color-drk-gray: #242424;
  --color-blue: #08fffc99;
  --color-lght-blue: #08fffbd7;
  --swiper-navigation-color: var(--color-white);
  --swiper-pagination-color: var(--color-white);

  --fs-dt-h1: calc(100vw / 7);
  --fs-dt-h2: 160px;
  --fs-dt-h3: 36px;
  --fs-dt-h4: 29px;
  --fs-dt-quote: 42px;
  --fs-dt-p: 15px;
  --fs-dt-btn1: 22px;
  --fs-dt-btn2: 16px;
  --fs-dt-d1: 20px;
  --fs-dt-d2: 12px;
  --fs-dt-mi: 22px;

  --fs-mb-h1: 57px;
  --fs-mb-h2: 38px;
  --fs-mb-h3: 24px;
  --fs-mb-h4: 22px;
  --fs-mb-quote: 20px;
  --fs-mb-p: 14px;
  --fs-mb-btn1: 18px;
  --fs-mb-btn2: 14px;
  --fs-mb-d1: 18px;
  --fs-mb-d2: 9px;
  --fs-mb-mi: 18px;
}
.container {
  position: relative;
  z-index: 1;
  padding: 0;
  width: 85%;
  min-width: unset;
  max-width: unset;
}
@media screen and (max-width: 900px) {
  .container {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 90%;
  }
}
div {
  cursor: default;
}

.header {
  z-index: 6;
}
section,
.contact {
  position: relative;
  z-index: 5;
}

.footer {
  position: relative;
  z-index: 0;
}
#root,
.App,
body {
  z-index: 0;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.App {
  min-height: 100dvh;
  width: 100%;
  overflow: hidden;
}
.App::after,
.App::before {
  pointer-events: none !important;
  content: '';
  position: absolute;
  height: 75vw;
  width: 75vw;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: -1;
  border-radius: 1480px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(8, 255, 252, 0.32) 0%,
    rgba(8, 211, 255, 0.32) 0.01%,
    rgba(8, 255, 252, 0) 100%
  );
}
.App::before {
  top: 300px;
}
.App::after {
  bottom: 0;
}
@media screen and (max-width: 500px) {
  .App::after,
  .App::before {
    width: 100vw;
    height: 100vw;
  }
  .App::after {
    bottom: 340vw;
  }
}
#particles-js {
  height: 100%;
  width: 100%;
  overflow: visible;
}

#particles-js canvas {
  height: 100%;
  width: 100%;
}

.pointer-none {
  pointer-events: none;
}
.pointer-all {
  pointer-events: all !important;
}
