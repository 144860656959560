.portfolios_container {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: auto;
  row-gap: 15px;
  column-gap: 15px;
}

@media screen and (max-width: 1330px) {
  .portfolios_container {
    width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .portfolios_container {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .portfolios_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
