.project-page {
  padding-top: 70px;
}

.project-content .headline2 {
  font-size: calc(var(--fs-dt-h2) / 1.2);
}
@media screen and (max-width: 1200px) {
  .project-content .headline2 {
    font-size: calc(var(--fs-dt-h2) / 1.4);
  }
}
@media screen and (max-width: 1000px) {
  .project-content .headline2 {
    font-size: calc(var(--fs-dt-h2) / 1.8);
  }
}

@media screen and (max-width: 800px) {
  .project-content .headline2 {
    font-size: calc(var(--fs-dt-h2) / 2.2);
  }
}

@media screen and (max-width: 620px) {
  .project-content .headline2 {
    font-size: calc(var(--fs-dt-h2) / 2.4);
  }
}

@media screen and (max-width: 460px) {
  .project-content .headline2 {
    font-size: calc(var(--fs-mb-h2) / 1);
  }
}

.project-page .purple .icon {
  left: 3%;
  height: 95%;
  bottom: 2.5%;
}
.project-head-img .icon {
  position: absolute;
  height: 120%;
  left: -10%;
  bottom: 0;
}
.project-head-img .bg {
  position: absolute;
  height: 100%;
  top: auto;
  left: auto;
  right: -10%;
  bottom: -20%;
}
.project-head-img .phones {
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 0.5em;
}
.project-head-img .phones img {
  max-height: 500px;
  width: 100%;
  height: auto;
  object-fit: contain;
}
.project-head-img {
  min-width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-content .headline2 {
  width: 100%;
  display: flex;
  padding: 40px 0 20px 0;
  line-height: 0.85em;
}

.project-content .headline2 span {
  width: 70%;
  margin-left: auto;
  padding-right: 5%;
}
.project-content .headline2.website span {
  width: 100%;
}
.content {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content .left-side {
  min-width: 30%;
  width: 30%;
}
.content .left-side h2,
.content .left-side h6,
.content .left-side .child {
  text-align: left;
  align-items: baseline;
  padding-top: 30px;
  width: 85%;
  text-transform: uppercase;
}
.content .right-side {
  margin-left: auto;
  width: 70%;
}
#intro .content .right-side {
  width: 80%;
}

.aboute-service .btn,
.content .right-side .paragraph {
  font-weight: 300;
  width: 82%;
}
.sub_text .quote {
  line-height: 1.2;
  padding-bottom: 20px;
}

.content .right-side .paragraph::first-letter,
.sub_text .quote::first-letter {
  text-transform: uppercase;
}

.project-carousel {
  padding: 40px 0;
}

.aboute-service {
  margin: 40px 0;
  padding: 40px 0;
  border-top: 1px solid var(--color-gray);
  width: 100%;
  display: flex;
}
.aboute-service .headline3 {
  width: 100%;
  padding-bottom: 20px;
}
.aboute-service .headline3 span {
  width: 40% !important;
  display: inline-block;
}
.aboute-service p.paragraph {
  width: 60% !important;
  color: var(--color-gray);
}
.aboute-service a {
  width: 70%;
  display: flex;
}
.aboute-service .btn {
  padding: 20px 0;
  border-radius: 50px;
}

@media screen and (max-width: 1110px) {
  .content {
    display: block;
  }
  .content .right-side,
  #intro.content .right-side {
    margin-left: auto;
  }

  .content .left-side,
  .content .right-side,
  #intro.content .right-side {
    width: 80%;
    display: block;
  }
}

@media screen and (max-width: 790px) {
  .project-content .headline2 span {
    width: 85%;
  }
  .content .left-side,
  .content .right-side,
  #intro.content .right-side {
    width: 85%;
    display: block;
  }

  .project-head-img .phones img {
    max-height: auto;
  }
}

@media screen and (max-width: 568px) {
  .content .left-side,
  .content .right-side,
  #intro.content .right-side {
    width: 100%;
  }

  .project-content .headline2 span {
    width: 100%;
  }

  .content .right-side,
  #intro.content .right-side {
    padding: 0;
  }
  .content .right-side .paragraph {
    width: 100%;
  }

  .aboute-service p.paragraph {
    width: 100% !important;
  }

  .aboute-service a,
  .aboute-service .btn {
    width: 100%;
  }
  .aboute-service .btn {
    padding: 15px 0;
  }
}

@media screen and (max-width: 550px) {
  .content .left-side,
  .content .right-side,
  #intro.content .right-side {
    width: 100%;
    width: 100% !important;
  }
}

@media screen and (max-width: 430px) {
  .project-head-img {
    border-radius: 23px;
    height: 250px;
  }
  .project-head-img .icon {
    height: 100%;
    top: -100px;
    left: -50px;
  }
  .project-head-img .bg {
    left: 40%;
  }
  .project-head-img .phones {
    height: 90%;
    gap: 0.1em;
  }
  .project-head-img .phones img {
    padding: 0;
  }
  .project-head-img .phones img:first-child {
    height: 195px;
  }
  .project-content .heading {
    width: 100%;
  }
  .sub_text .quote {
    font-size: var(--fs-mb-quote);
  }
  .project-carousel {
    padding-top: 20px;
  }
  .project-carousel h6 {
    padding-bottom: 15px;
  }

  .project-content h1 {
    width: 60%;
    margin-left: 0;
    padding-top: 20px;
  }
  .aboute-service {
    padding-bottom: 0;
  }
  .aboute-service h1 {
    line-height: 30px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.swiper-scrollbar-drag,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  display: none;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 3em !important;
  opacity: 0.6 !important;
}

.swiper-pagination-bullet {
  background: var(--color-white);
  width: 0.3em;
  height: 0.3em !important;
  border-radius: 10px !important;
  transition: all 0.3s ease-in-out;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.2em !important;
}
