.swiper-button-next,
.swiper-button-prev {
  height: calc(var(--swiper-navigation-size) / 1.3);
  width: calc(var(--swiper-navigation-size) / 1.3);
  border-radius: 50%;
  background-color: rgba(221, 221, 221, 0.5);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: calc(var(--swiper-navigation-size) / 2.5);
  color: rgba(85, 85, 85, 0.9);
}

.swiper-button-next::after {
  margin-left: 0.15em;
}

.swiper-button-prev::after {
  margin-right: 0.15em;
}
.swiper-pagination-bullet {
  background: rgba(234, 232, 232, 0.9);
}

.swiper {
  pointer-events: all !important;
}

.swiper-slide {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.carousel-img {
  height: 400px;
  max-height: 400px;
}
.carousel-loader {
  width: 100%;
  height: 400px;
  max-height: 400px;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 1190px) {
  .carousel-img {
    height: 350px;
    max-height: 350px;
  }
  .carousel-loader {
    height: 350px;
    max-height: 350px;
  }
}

@media screen and (max-width: 968px) {
  .carousel-img {
    height: 300px;
    max-height: 300px;
  }
  .carousel-loader {
    height: 300px;
    max-height: 300px;
  }
}

@media screen and (max-width: 810px) {
  .carousel-img {
    height: 250px;
    max-height: 250px;
  }
  .carousel-loader {
    height: 250px;
    max-height: 250px;
  }
}

@media screen and (max-width: 670px) {
  .carousel-img {
    height: 150px;
    max-height: 150px;
  }
  .carousel-loader {
    height: 150px;
    max-height: 150px;
  }
}
