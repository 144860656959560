header {
  background-color: #0d0d0d;
  backdrop-filter: blur(220px);
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0 !important;
}
.mr-3 {
  margin-right: 2rem !important;
}

header {
  background-color: var(--color-black);
  backdrop-filter: blur(220px);
  position: relative;
  z-index: 10;
  margin: 0;
}

.header-logo {
  color: var(--color-white);
  fill: var(--color-white);
  width: 43px;
  transition: all 0.2s ease-in-out;
}

.header-logo:hover {
  fill: var(--color-ylw);
}

header .container {
  border-bottom: solid 1px var(--color-gray);
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}
header .logo {
  width: 50px;
  margin-right: 20px;
}
#navbar {
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}
#navbarResponsive {
  width: 100vw;
  z-index: 110;
  position: fixed;
  top: 94px;
  right: 0;
  left: 0;
  z-index: 50;
  background-color: var(--color-black);
}
.header:has(.show) {
  padding-top: 95px;
}
.header:has(.show) > header {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
}

.navbar-modal {
  overflow: hidden;
  height: 100vh;
  z-index: 100;
}

#navbarResponsive ul,
#navbarResponsive .navbar-modal {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  transition: all 0.2s linear;
}
header ul {
  display: flex;
}
#navbarResponsive li,
header .container .nav li {
  padding: 1.5rem 1rem;
  font-family: 'Exo 2', sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: 26.4px;
}
.nav-link {
  z-index: 1;
  text-decoration: none;
  padding: 0;
  position: relative;
  color: var(--color-white);
  scroll-behavior: smooth;
  transition: all 0.3s ease;
}
.nav-link:hover,
.active-link:hover {
  color: var(--color-ylw);
}

.active-link::after {
  color: var(--color-ylw);
  content: '';
  border-bottom: 1px solid var(--color-white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  transition: all 0.3s ease;
}

.active-link:hover::after {
  border-bottom-color: var(--color-ylw);
}
@media screen and (max-width: 997px) {
  header .container {
    padding: 15px 0;
  }
  header .logo {
    margin-right: 0;
    margin-left: 14px;
  }
  header .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  header .navbar-toggler {
    display: inherit;
  }
  #navbar {
    display: none;
  }
}
@media screen and (max-width: 430px) {
  header .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 15px 5%;
  }
  header .navbar-toggler {
    display: inherit;
  }
}
