.partners-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 100px;
  width: 100%;
}
.partner {
  flex: 1;
}

.partner svg {
  width: 100%;
}
@media screen and (max-width: 760px) {
  .partners-list {
    flex-direction: column;
    align-items: center;
  }
  .partner {
    flex: 1;
    padding: 3px 20px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .partners .left-side,
  .ourteam-title {
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 30px;
  }
  .partners .headline3,
  .ourteam-title .headline3 {
    width: auto !important;
  }
}
