.footer {
  background-color: var(--color-black);
  border-top: 2px solid var(--color-gray);
}
.footer .footer-container {
  padding: 60px 0;
  color: var(--color-white);
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.footer .side {
  color: var(--color-white);
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 90%;
}
.footer-logo {
  margin-left: -6px;
  margin-top: -15px;
  width: 204px;
}

.footer-company {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.copyright {
  color: var(--color-gray);
}
.footer-contacts {
  display: flex;
  flex-direction: column;
}
.footer-contacts-item {
  background-color: transparent;
  padding: 0;
  border: none;
  flex: 1;
  margin: 10px 0;
  cursor: pointer;
  color: var(--color-white) !important;
}

.footer-contacts-item a {
  white-space: nowrap;
  cursor: pointer !important;
  color: var(--color-white);
  text-decoration: none;
}
.footer-contacts-item a:hover {
  color: var(--color-grey);
}
.footer-contents {
  display: flex;
  width: min-content;
  flex-direction: column;
  row-gap: 15px;
}
.footer-contents .content-item {
  color: var(--color-white);
  text-decoration: none;
  cursor: pointer;
  text-align: left;
}
.footer-icons {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-left: auto;
}
.footer-icon {
  width: 36px;
}
.footer-icon svg {
  width: 100%;
  fill: var(--color-ylw);
}

.footer-icon:hover svg {
  fill: var(--color-ylw);
}
.footer-conf {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: auto;
  padding-bottom: 35px;
}
.footer-conf-item {
  color: var(--color-gray);
  text-decoration: none;
  transition: all 0.2s;
}

.footer-conf-item:hover,
.footer-contents .content-item:hover {
  color: var(--color-ylw);
}

@media screen and (max-width: 837px) {
  .footer .footer-container {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }

  .footer-logo {
    margin-top: 0;
  }
  .footer-container .side {
    flex-direction: column;
    padding: 20px 0;
    height: 100%;
  }
  .footer-container .side:first-child {
    padding-top: 40px;
  }
  .footer-container .side:last-child {
    position: relative;
    gap: 30px;
  }
  .footer-container .side:last-child::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid var(--color-gray);
  }

  .footer-icons {
    padding-top: 20px;
    margin-left: 0;
    justify-content: flex-start;
  }
  .footer-contents {
    display: flex;
    padding-top: 10px;
  }
  .footer-contents .content-item {
    display: flex;
    width: min-content;
  }
  .footer-conf,
  .copyright {
    white-space: nowrap;
    text-align: right;
  }

  .footer-conf {
    position: absolute;
    height: 50%;
    right: 0;
    bottom: 0;
    padding-bottom: 0;
  }
  .footer-conf-item {
    display: flex;
    margin-left: auto;
  }
}
@media screen and (max-width: 760px) {
  .footer .footer-container {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 470px) {
  .footer .footer-container {
    width: min-content;
  }

  .footer .footer-container .side,
  .footer-contacts-item {
    width: min-content;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-logo {
    margin: 0 auto;
  }
}
