.ourteam-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 19px;
  row-gap: 75px;
}
.ourteam-items img {
  width: 100%;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
}
.ourteam-item {
  height: 174px;
  display: grid;
  grid-template-columns: 174px 1fr;
}
.ourteam-item .img {
  width: 100%;
  overflow: hidden;
  padding: 0;
  border-radius: 20px;
}
.ourteam-items .img:hover img {
  width: 100%;
  transform: scale(1.1, 1.1);
}
.ourteam-item .info {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
}
.ourteam-item .info .dsc1 span {
  display: block;
}
.ourteam-item .info .dsc1 {
  padding-bottom: 0.4em;
  padding-top: 0.2em;
}
.ourteam-item h6 {
  text-transform: uppercase;
  color: var(--color-white);
}

.ourteam-item p {
  color: var(--color-gray);
  transition: all 0.15s ease-in-out;
}
.ourteam-title {
  padding-bottom: 15px;
}
@media screen and (max-width: 1090px) {
  .ourteam-item {
    height: 160px;
    grid-template-columns: 160px 1fr;
  }
}
@media screen and (max-width: 760px) {
  .ourteam-title {
    width: auto;
  }
  .ourteam-items {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
  .ourteam-item {
    height: 157px;
    grid-template-columns: 157px 1fr;
  }
  .ourteam-item .img {
    padding-left: 7%;
    height: 100%;
  }
  .ourteam-item img {
    object-fit: cover;
  }
}
