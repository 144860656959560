.services {
  height: 100%;
  padding: 100px 0;
}
.services-title {
  padding-bottom: 40px;
  color: var(--color-white);
}
.services-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.services-container .ellipse-little-container {
  margin-top: 9px;
  width: 10em;
  height: 10em;
}
.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
  gap: 16px;
}
.grid-item:nth-child(1) {
  grid-row: span 2;
}
.grid-item:nth-child(2) {
  grid-column: span 2;
}
.grid-item:nth-child(3) {
  grid-row: span 2;
}
.grid-item:nth-child(5) {
  grid-row: span 2;
}
.grid-item:nth-child(6) {
  grid-column: span 2;
}

@media screen and (max-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item:nth-child(1) {
    grid-row: span 1;
  }
  .grid-item:nth-child(2) {
    grid-row: span 2;
    grid-column: span 1;
  }
  .grid-item:nth-child(3) {
    grid-row: span 1;
    grid-column: span 1;
  }
  .grid-item:nth-child(4) {
    grid-row: span 1;
    grid-column: span 2;
  }
  .grid-item:nth-child(5) {
    grid-row: span 1;
    grid-column: span 1;
  }
  .grid-item:nth-child(6) {
    grid-column: span 1;
    grid-row: span 2;
  }
}

@media screen and (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .grid-container {
    display: block;
  }
  .grid-item:nth-child(n) {
    padding: 7px 0;
    grid-column: span 1;
  }
}

.services .table-card {
  overflow: hidden;
  height: 0;
  align-items: stretch;
  width: 100%;
  padding-bottom: 15px;
  color: white;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  background: linear-gradient(to bottom, rgba(139, 139, 139, 0.833), rgb(33, 33, 33));

  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
}
.services .table-card::after {
  content: '';
  position: absolute;
  background: rgb(53, 53, 54);
  margin: 1px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  border-radius: 30px;
  transition: all 0.5s ease-in-out;
}
.table-card-body {
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  padding: 40px 32px;
  font-family: 'Exo 2', sans-serif;
}
.table-card-body-title {
  color: var(--color-ylw);
}
.table-card-body p {
  padding-top: 10px;
  line-height: 1.5em;
  color: var(--color-gray);
}
.table-card-body img {
  width: 145px;
  padding: 20px 0;
}
.table-card-body .position-absolute {
  text-decoration: none;
  bottom: 20px;
  right: 40px;
}
.services-container .animate {
  height: 100%;
}
.services-container .cursor-pointer {
  cursor: pointer !important;
  text-decoration: none;
}

.services-container .show {
  height: inherit;
}

.services-container .hidden {
  height: 0;
}
.services-container .ellipse-little-container {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 100px;
  }
  .services table {
    width: 100%;
  }
  .table-card-body {
    padding: 30px;
  }
  .services-container .table-card {
    border-radius: 17px;
  }
  .services-container .table-card::after {
    border-radius: 17px;
  }
  .table-card-body p {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 430px) {
  .services {
    margin-bottom: 0;
  }
  .services-container .animate {
    width: 100%;
  }
  .services-container .table-card {
    border-radius: 17px;
  }
  .services-container .table-card::after {
    border-radius: 17px;
  }
  .services-title {
    padding-bottom: 15px;
  }
  .table-card-body {
    padding: 25px;
  }
  .table-card-body .more {
    bottom: 17px;
  }
}
