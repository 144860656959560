.service-page .heading {
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}
.service-page .heading .headline2:not(.too-long) {
  line-height: 0.97em;
  font-size: calc(100vw / 10.2);
}

.service-page .heading .headline2.too-long {
  font-size: calc(100vw / 15) !important;
}

.service-page .heading .headline2::first-line {
  color: var(--color-white);
}
.service-page .heading .quote {
  color: var(--color-white);
  width: 60%;
}
.service_page-content {
  padding-top: 50px;
  padding-bottom: 50px;
}
.service_page-content .content_heading {
  display: block;
}
.service_page-content .left-side,
.service_page-content .left-side .child,
.service_page-content .content_heading {
  padding-top: 0;
}

.develope_steps {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  align-items: center;
}
.develope_steps .step {
  padding: 10px 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 10%;
  align-items: center;
  border-top: 1px solid var(--color-gray);
}
.develope_steps .step:last-child {
  border-bottom: 1px solid var(--color-gray);
}
.develope_steps .step-text {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.develope_steps .headline4,
.develope_steps .paragraph {
  width: 40% !important;
  align-self: center;
  padding-bottom: 0 !important;
}

.develope_steps .paragraph {
  color: var(--color-gray);
}
.develope_steps .step-number {
  display: flex;
}
.step-number h3 {
  width: min-content !important;
  margin-left: auto;
}
.admin {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 80%;
}
.service_page-content .paragraph:not(.sub_text) {
  padding-bottom: 30px;
}

.admin img {
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .admin {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .service-page .heading .quote {
    width: 100%;
  }

  .develope_steps .step-text {
    flex-direction: column;
    text-align: start;
  }
  .develope_steps .headline4,
  .develope_steps .paragraph {
    width: 100% !important;
  }
  .sub_text {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .service-page .heading .quote {
    padding-top: 10px;
    width: 100%;
  }
  .service-page .content {
    margin-bottom: 20px;
  }
  .service_page-content {
    padding-top: 0;
    padding-bottom: 25px;
  }
  .develope_steps {
    grid-auto-rows: unset;
  }
  .develope_steps .step {
    grid-template-columns: 1fr;
    padding: 18px 0;
    align-items: baseline;
    position: relative;
  }
  .develope_steps .step-text {
    flex-direction: column;
    justify-content: center;
  }
  .step-text .step-title {
    padding: 0 0 7px 0;
  }
  .develope_steps .step-number {
    position: absolute;
    top: 20px;
    right: 0;
    align-items: flex-start;
  }
  .develope_steps .step-number h3 {
    height: auto;
  }
  .end-text {
    padding-top: 110px;
  }
}
