.intro {
  text-align: left;
  height: 100vh;
  z-index: -5;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
}
.intro h1::first-line {
  color: var(--color-white);
}
.intro h1 {
  padding-top: 20px;
  text-align: center;
  color: var(--color-white);
}

.headline2 {
  color: var(--color-ylw);
}
.yellow {
  color: var(--color-ylw);
}
.white {
  color: var(--color-white);
}
.intro .order-project {
  text-align: left;
  position: relative;
}

.intro .order-project p {
  width: 70%;
  padding: 0;
}
.intro__img {
  position: absolute;
  right: -120px;
  bottom: 0;
  width: 80%;
  animation: intropic 2s linear alternate infinite;
}
.intro__img .img {
  width: auto;
  height: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intro__img img {
  width: 100%;
}
.intro__btn {
  width: 30%;
  padding-top: 30px;
}
.order-project {
  margin-top: -30px;
}

@media screen and (max-width: 1310px) {
  .intro {
    height: 100%;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 1000px) {
  .intro {
    height: 100%;
    padding-bottom: 150px;
  }
  .blurred-round {
    width: 100vw;
    height: 100vw;
  }
  .order-project {
    margin-top: 0;
  }
  .intro .order-project p {
    text-align: right;
    width: 100%;
    line-height: 25px;
  }
  .intro .order-project .intro__img {
    position: relative;
    width: 100%;
    margin: 20px 0;
    right: 0;
  }
  .intro__img img {
    width: 100%;
  }
  .intro__btn {
    width: 80%;
    margin: 0 auto;
    display: block;
    padding-top: 10px;
  }
}

@media screen and (max-width: 600px) {
  .intro {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 450px) {
  .intro {
    padding-bottom: 0px;
  }
  .intro h1 {
    margin-right: auto;
    margin-left: auto;
    font-size: calc(100vw / 6.5);
    font-weight: 500;
  }
  .intro .order-project p {
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
  }
  .intro .order-project .intro__img {
    width: 100%;
  }
  .intro__img img {
    width: 100%;
  }
  .intro__btn {
    width: 100%;
  }
}

@keyframes intropic {
  0% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}
