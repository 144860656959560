#intro .content .headline3 {
  color: var(--color-white);
  width: 90%;
}

#intro .content {
  margin-bottom: 100px;
}

.circle {
  height: 100%;
  width: 100%;
  top: 0;
  left: -5%;
  right: 5%;
  bottom: 0;
  z-index: -1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ellipse-container {
  pointer-events: all;
  height: 700px;
  width: 700px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(0deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ellipse-container span,
.ellipse-little-container span {
  --color-circle: #ffffff73;
  content: '';
  position: absolute;
  display: block;
  border: 1px solid var(--color-circle);
  box-shadow:
    0 1px 0 var(--color-circle),
    inset 0 1px 0 var(--color-circle);
  box-sizing: border-box;
  border-radius: 50%;
  transform-style: preserve-3d;
}
.ellipse-container span div {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
}
.ellipse-container span div::before,
.ellipse-container span div::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  border: 1px solid var(--color-circle);
}
.ellipse-container span div::after {
  transform: translateZ(-0.1px);
}
.ellipse-container span div::before {
  transform: translateZ(1px);
}
.ellipse-container .ellipse-1 {
  top: 0%;
  height: 100%;
  width: 100%;
}
.ellipse-container .ellipse-little-container {
  height: 40%;
  width: 40%;
}
.ellipse-little-container {
  position: relative;
}

.ellipse-little-container .ellipse-2 {
  width: 100%;
  height: 100%;
}
.ellipse-little-container .ellipse-3 {
  animation: ellipse-3 5s linear infinite;
}

.ellipse-little-container .ellipse-3:nth-child(1) {
  width: 100%;
  height: 100%;
}
.ellipse-little-container .ellipse-3:nth-child(2) {
  animation-delay: -200ms;
  height: 90%;
  width: 90%;
  left: 5%;
  top: 5%;
}
.ellipse-little-container .ellipse-3:nth-child(3) {
  animation-delay: -400ms;
  height: 80%;
  top: 10%;
  width: 80%;
  left: 10%;
}
.ellipse-little-container .ellipse-3:nth-child(4) {
  animation-delay: -600ms;
  height: 70%;
  top: 15%;
  width: 70%;
  left: 15%;
}
.ellipse-little-container .ellipse-3:nth-child(5) {
  animation-delay: -800ms;
  height: 60%;
  top: 20%;
  width: 60%;
  left: 20%;
}
.ellipse-little-container .ellipse-3:nth-child(6) {
  animation-delay: -1s;
  height: 50%;
  top: 25%;
  width: 50%;
  left: 25%;
}
.ellipse-little-container .ellipse-3:nth-child(7) {
  animation-delay: -1200ms;
  height: 40%;
  top: 30%;
  width: 40%;
  left: 30%;
}
.ellipse-little-container .ellipse-3:nth-child(8) {
  animation-delay: -1400ms;
  height: 30%;
  top: 35%;
  width: 30%;
  left: 35%;
}

.ellipse-container .ellipse-1:nth-child(1) {
  animation: ellipse-1-1 5s linear infinite;
  transform: rotate(30deg) translateZ(10px);
}
.ellipse-container .ellipse-1:nth-child(2) {
  animation: ellipse-1-2 5s linear infinite;
  animation-delay: -7s;
}

@keyframes ellipse-1-1 {
  0% {
    transform: rotate(40deg) rotate3d(0, 0, 0, 0deg);
  }
  100% {
    transform: rotate(40deg) rotate3d(0, 1, 0, 360deg);
  }
}
@keyframes ellipse-1-2 {
  0% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@keyframes ellipse-3 {
  0% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@media screen and (max-width: 1310px) {
  .portfolios_page .headline2 {
    font-size: calc(var(--fs-dt-h2) - 2rem);
  }
}
@media screen and (max-width: 1110px) {
  .portfolios_page .headline2 {
    font-size: calc(var(--fs-dt-h2) - 4rem);
  }
}
@media screen and (max-width: 810px) {
  .portfolios_page .headline2 {
    font-size: calc(var(--fs-dt-h2) - 6rem);
  }
}
@media screen and (max-width: 810px) {
  .portfolios_page .headline2 {
    font-size: calc(var(--fs-dt-h2) - 7.5rem);
  }
  .content,
  #intro .content {
    display: block;
  }

  .content .right-side {
    width: 90% !important;
    margin-left: auto;
  }
}
@media screen and (max-width: 1110px) {
}
.portfolio-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}
.portfolio-card {
  cursor: pointer !important;
  text-decoration: none;
  min-width: 420px;
  flex: 1;
  z-index: 0;
  padding: 40px;
  border-radius: 40px;
  display: flex;

  overflow: hidden;
  position: relative;
  color: var(--color-white);
  transition: all 1.5s ease-in;
}
.portfolio-card .paragraph {
  color: var(--color-white);
}

.group:hover .portfolio-card-text {
  transform: translateY(-15em);
}
.group:hover .portfolio-card-img-svg img {
  transform: translateX(-200%);
}
.group:hover .portfolio-card-img-phone {
  transform: translateX(-4em) translateY(-3em) scale(1.2, 1.2);
}
.portfolio-card:hover .position-absolute {
  transform: translateY(60px);
}
.portfolio-card-img-svg img,
.portfolio-card-img-phone,
.portfolio-card-text,
.portfolio-card .position-absolute {
  transition: all 1s linear;
}
.portfolio-card-img {
  height: min-content;
  cursor: pointer !important;
  position: relative;
}
.portfolio-card-img-svg {
  cursor: pointer !important;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 1s linear;
}
.group .portfolio-card-img-svg {
  height: 100%;
  padding-bottom: 30%;
  padding-right: 20%;
}
.group .portfolio-card-img-svg img {
  cursor: pointer !important;
  height: 100%;
}
.group .portfolio-card-img-phone {
  cursor: pointer !important;
  position: relative;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  right: -40px;
}
.group .portfolio-card-img-phone .bg {
  cursor: pointer !important;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 45px;
  overflow: visible;
}
.group .portfolio-card-img-phone .phone {
  cursor: pointer !important;
  position: relative;
  z-index: 5;
  margin-right: 5px;
  height: 100%;
  width: min-content;
  margin-left: auto;
}
.group .portfolio-card-img-phone .phone img {
  width: auto;
  height: auto;
  max-width: 345px;
  max-height: 345px;
}

.portfolio-link {
  width: 100%;
  padding: 40px 0;
}
.portfolio-card .position-absolute {
  bottom: 35px;
}
.group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.single {
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/*  HOVER  */

.single.portfolio-card:hover .portfolio-card-text {
  transform: translateY(-12rem);
}
.single.portfolio-card:hover .portfolio-card-img-svg {
  transform: translateX(-100%);
}
.single.portfolio-card:hover .portfolio-card-img-phone .phone {
  right: 20%;
  transform: scale(1, 1);
}
.single:hover .portfolio-card-img-phone .bg {
  left: -9%;
}
.single .portfolio-card-img-phone .phone,
.single .portfolio-card-img-phone .bg {
  transition: all 1s linear;
}
/*----------------------------------------------*/
.portfolio-card-text {
  display: flex;
  flex-direction: column;
}
.single .portfolio-card-text {
  flex: 1;
  z-index: 3;
}

.single .portfolio-card-img {
  height: min-content;
  flex: 1;
}

.single .portfolio-card-img-phone {
  cursor: pointer !important;
  position: relative;
  display: flex;
  object-fit: unset;
  height: 100%;
  width: auto;
  margin-left: auto;
  z-index: 2;
}
.single .portfolio-card-img-phone .phone {
  cursor: pointer !important;
  z-index: 2;
  right: 0;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}

.single .portfolio-card-img-phone .phone img {
  max-height: 345px;
  max-width: 345px;
  height: auto;
  width: auto;
  margin-left: auto;
  object-fit: contain;
}
.single .portfolio-card-img-phone .bg {
  cursor: pointer !important;
  position: absolute;
  padding: 45px 0;
  margin-right: auto;
  min-width: 50%;
  max-width: 80%;
  max-height: 100%;
  height: auto;
  width: fit-content;
  left: 24%;
  object-fit: contain;
}

.single .portfolio-card-img-svg {
  cursor: pointer !important;
  height: 100%;
  width: 100%;
  top: 10px;
  left: -5%;
  padding: 0;
  display: flex;
  align-items: center;
}
.single .portfolio-card-img-svg img {
  cursor: pointer !important;
  height: 80%;
  width: 100%;
  object-fit: cover;
}
.single .position-absolute {
  left: 5%;
}

@media screen and (max-width: 1310px) {
  .portfolio-card {
    min-width: 370px;
  }
  .group .portfolio-card-img {
    height: min-content;
  }

  .group .portfolio-card-img-phone .phone img {
    max-width: 265px;
    max-height: 265px;
  }

  .single.portfolio-card:hover .portfolio-card-img-svg {
    transform: translateX(-70%);
  }
  .single.portfolio-card:hover .portfolio-card-img-phone .phone {
    right: 17%;
  }
  .single.portfolio-card:hover .portfolio-card-img-phone .bg {
    right: 37%;
  }
  .single.portfolio-card .portfolio-card-img-phone .phone {
    right: -9%;
  }

  .single .portfolio-card-img-svg {
    left: -10%;
  }
  .single .portfolio-card-img-phone .bg {
    left: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .group.portfolio-card:hover .portfolio-card-img-svg img {
    transform: translateX(-18em);
  }
  .portfolio-card {
    min-width: 340px;
    padding: 30px;
  }

  .group .portfolio-card-img-svg {
    padding-bottom: 10%;
  }
  .service-page .portfolio-cards,
  #intro .portfolio-cards {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

@media screen and (max-width: 740px) {
  .group.portfolio-card:hover .portfolio-card-img-phone {
    transform: translateX(-30%) translateY(-1.2em) scale(1.3, 1.3);
  }
  .heading h1 {
    font-size: 120px;
  }
  .portfolios_page .portfolios_container {
    width: 100%;
  }

  .portfolio-cards {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .portfolio-card {
    min-width: 370px;
    padding: 25px;
    border-radius: 17px;
    height: min-content;
    height: 300px;
  }
  .portfolio-card-text h5 {
    font-size: 22px;
  }
  .portfolio-card-text p {
    font-size: 14px;
  }
  .portfolio-card {
    padding: 25px;
    border-radius: 17px;
    height: 100%;
  }
  .portfolio-card-img {
    height: 100%;
  }
  .group .portfolio-card-img-phone {
    position: static;
    display: flex;
    object-fit: unset;
    height: 100%;
    width: auto;
    margin-left: auto;
  }
  .group .portfolio-card-img-phone .phone {
    margin-left: auto;
    margin-right: -15px;
    height: auto;
    width: auto;
  }

  .group .portfolio-card-img-phone .bg {
    height: 100%;
    width: unset;
    right: 7%;
    padding: 40px;
  }

  .group .portfolio-card-img-svg img {
    height: 100%;
    width: auto;
  }
}
@media screen and (max-width: 535px) {
  .ellipse-container {
    height: 300px;
    width: 300px;
  }
  .group.portfolio-card:hover .portfolio-card-text {
    transform: translateY(-240%);
  }
  .group.portfolio-card:hover .portfolio-card-img-svg img {
    transform: translateX(-400%);
  }
  .group.portfolio-card:hover .portfolio-card-img-phone {
    transform: translateX(-15%) translateY(-35px) scale(1.2, 1.2);
  }
  .group.portfolio-card:hover .min_btn {
    transform: translateY(100px);
  }

  .portfolio {
    display: block;
  }
  .portfolio-card {
    min-width: unset;
    padding: 25px;
    border-radius: 17px;
    height: 100%;
  }
  .group .portfolio-card-img {
    height: 240px;
  }
  .group .portfolio-card-img-phone {
    position: static;
    display: flex;
    object-fit: unset;
    height: 100%;
    width: auto;
    margin-left: auto;
  }
  .group .portfolio-card-img-phone .phone {
    margin-right: -15px;
  }
  .group .portfolio-card-img-phone .phone img {
    max-width: 245px;
    max-height: 245px;
  }
  .group .portfolio-card-img-phone .bg {
    height: 100%;
    right: 15px;
    width: unset;
    padding: 40px;
    right: 7%;
  }

  .group .portfolio-card-img-svg {
    height: 100%;
    top: 10px;
    left: 0;
    padding-bottom: 30%;
  }
}
