.headline1 {
  font-family: var(--ff-bowler);
  font-size: var(--fs-dt-h1);
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 1em;
}
.headline2 {
  font-family: var(--ff-bowler);
  font-size: var(--fs-dt-h2);
  font-weight: 400;
  line-height: normal;
}

.headline3 {
  font-family: var(--ff-bowler);
  font-size: var(--fs-dt-h3);
  font-weight: 400;
}
.headline4 {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-h4);
  font-weight: 700;
}

.headline4::first-letter {
  text-transform: uppercase;
}
.quote {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-quote);
  font-weight: 500;
  line-height: normal;
}
.paragraph {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-p);
  font-weight: 400;
}
.btn1 {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-btn1);
  font-weight: 500;
}
.btn2 {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-btn2);
  font-weight: 500;
}
.dsc1 {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-d1);
  font-weight: 400;
}

.dsc2 {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-d2);
  font-weight: 400;
}
.menu-i {
  font-family: var(--ff-exo-2);
  font-size: var(--fs-dt-mi);
  font-weight: 500;
}
.nav-link:active,
.nav-link:focus {
  text-decoration: 1px solid var(--color-white);
  color: unset;
}

@media screen and (max-width: 1090px) {
  .headline2 {
    font-size: calc(var(--fs-dt-h2) - 29px);
  }
  .headline4 {
    font-size: calc(var(--fs-dt-h4) - 10px);
  }
  .quote {
    font-size: calc(var(--fs-dt-quote) - 10px);
  }
  .dsc1 {
    font-size: calc(var(--fs-dt-d1) - 5px);
  }
  .menu-i {
    font-size: calc(var(--fs-dt-mi) - 5px);
  }
}

@media screen and (max-width: 920px) {
  .headline2 {
    font-size: calc(var(--fs-mb-h2) + 55px);
  }
  .headline3 {
    font-size: calc(var(--fs-mb-h3) + 15px);
  }
  .headline4 {
    font-size: calc(var(--fs-mb-h4) + 10px);
  }
  .quote {
    font-size: calc(var(--fs-mb-quote) + 10px);
  }
  .menu-i {
    font-size: calc(var(--fs-mb-mi) + 5px);
  }
}

@media screen and (max-width: 615px) {
  .headline2 {
    font-size: calc(var(--fs-mb-h2) + 25px);
  }
  .headline3 {
    font-size: calc(var(--fs-mb-h3) + 15px);
  }
  .headline4 {
    font-size: calc(var(--fs-mb-h4) + 10px);
  }
  .quote {
    font-size: calc(var(--fs-mb-quote) + 10px);
  }
  .dsc1 {
    font-size: calc(var(--fs-mb-d1) + 5px);
  }
  .dsc2 {
    font-size: calc(var(--fs-mb-d2) + 5px);
  }
  .menu-i {
    font-size: calc(var(--fs-mb-mi) + 5px);
  }
}

@media screen and (max-width: 450px) {
  .headline1 {
    font-size: var(--fs-mb-h3);
  }
  .headline2 {
    font-size: var(--fs-mb-h2);
  }
  .headline3 {
    font-size: var(--fs-mb-h3);
  }
  .headline4 {
    font-size: var(--fs-mb-h4);
  }
  .quote {
    font-size: var(--fs-mb-quote);
  }
  .dsc1 {
    font-size: var(--fs-mb-d1);
  }
  .dsc2 {
    font-size: var(--fs-mb-d2);
  }
  .menu-i {
    font-size: var(--fs-mb-mi);
  }
}
.bold {
  font-weight: 700 !important;
}
